@import url('https://rsms.me/inter/inter.css');

* {
    --primary-blue: #007aff;
    --blue-bg: rgba(0, 122, 255, 0.2);
    --error-fg: #fb1e36;
    --error-rgb: 251, 30, 54;
    --error-bg: #fedce0;
    --warning-fg: #ffcc00;
    --accent: #e9edf0;
    --kinda-black: #2a2d33;
    --kinda-gray: #999999;

    box-sizing: border-box;
}
body {
    color: var(--text-color);
}

b,i {
    color: inherit;
}

a {
    text-decoration: none;
}

img {
    max-width: 100%;
}

:root {
    --content-width: 63rem;
    --content-width-sm: 40em;
}

body {
    margin: 0;
    padding: 0 1rem 0 1rem;
}

*, ::before, ::after {
    box-sizing: border-box;
}

@media (max-width: 40em) {
    body {
        @apply text-sm;
        padding: 1rem 0 0 0;
    }
}

/* Global Fonts */
* {
    font-family: 'Inter var', sans-serif;
    font-feature-settings: 'ss03', 'liga', 'tnum', 'zero', 'cv06', 'cv08',
    'cv10', 'cv11' 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

/* Global Color Variables */
body {
    --rgb-medium-gray: 226, 221, 215;
    --rgb-purple: 134, 126, 255;
    --rgb-red: 255, 120, 120;
    --pink-gradient: linear-gradient(160deg, #fc28fc, #fc1474);
}

body {
    --paper-shadow: 0 0 3rem rgba(var(--rgb-medium-gray), 1);
    --paper-shadow-sm: 0 0 1rem rgba(var(--rgb-medium-gray), 0.8);
}

.tc-shadow {
    box-shadow: var(--paper-shadow);
}

.tc-shadows-sm {
    box-shadow: var(--paper-shadow-sm);
}

.tc-rounded-lg {
    border-radius: 2em;


}

p, button {
    /*font-size: 1em;*/
}

button {
    cursor: pointer;
    background: none;
    /*outline: none;  */
    font-weight: bold;
}

body {
    margin: 0;
}

body {
    transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out, border-color 0.2s ease-in-out;
    --bg: white;
    --primary-blue: #007aff;
    --primary-pink: #FC1999;
    --blue-bg: rgba(0, 122, 255, 0.2);
    --green-bg: rgba(0, 194, 67, 0.2);
    --green: #34c759;
    --orange: #ff9500;

    --rgb-medium-gray: 226, 221, 215;
    --rgb-purple: 134, 126, 255;
    --rgb-red: 255, 120, 120;

    --depth-6: #f2f2f7;
    --depth-5: #e5e5ea;
    --depth-4: #d1d1d6;
    --depth-3: #c7c7cc;
    --depth-2: #aeaeb2;
    --depth-1: #8e8e93;

    --glimt: rgb(var(--rgb-medium-gray), 0.15);
    --paper-1: white;
    --paper-2: white;
    --paper-3: white;
    --paper-4: white;
    --paper-5: white;
    --paper-6: white;

    --paper-depth-1: var(--depth-6);
    --paper-depth-2: var(--depth-5);
    --paper-depth-3: var(--depth-4);

    --text-color: black;
    --text-color-inverted: white;

    --rgb-paper-shadow: 226, 221, 215;
    --rgba-shadow-sm: rgba(var(--rgb-paper-shadow), 0.4);
    --rgba-shadow-lg: rgba(var(--rgb-paper-shadow), 0.5);
    --paper-shadow-lg: 0 0 3rem rgba(var(--rgb-medium-gray), 1);

    --paper-shadow-md: 0 0 2rem rgba(var(--rgb-medium-gray), 0.9);

    --paper-shadow: 0 0 3rem var(--rgba-shadow-lg);
    --paper-shadow-top: 0 -2.2rem 2rem var(--rgba-shadow-sm);
    --paper-shadow-bottom: 0 2.2rem 2rem var(--rgba-shadow-sm);
    --paper-shadow-sm: 0 0 1rem var(--depth-6);

    --pink-gradient: linear-gradient(160deg, #fc28fc, #fc1474);
}

@media (prefers-color-scheme: dark) {
    body {
        --green: #32d74b;
        --orange: #ff9f0a;


        --depth-6: #1c1c1e;
        --depth-5: #2c2c2e;
        --depth-4: #3a3a3c;
        --depth-3: #48484a;
        --depth-2: #636366;
        --depth-1: #8e8e93;

        --rgb-medium-gray:  51, 60, 71;

        --paper-1: var(--depth-6);
        --paper-2: var(--depth-5);
        --paper-3: var(--depth-4);
        --paper-4: var(--depth-3);
        --paper-5: var(--depth-2);
        --paper-6: var(--depth-1);

        --paper-depth-1: var(--depth-5);
        --paper-depth-2: var(--depth-4);
        --paper-depth-3: var(--depth-3);

        --bg: #0d0d0d;

        --text-color: white;
        --text-color-inverted: black;


        --rgb-paper-shadow: 0, 0, 0;
        --rgba-shadow-sm: none;
        --paper-shadow-sm: none;
        --paper-shadow-lg: none;
        --paper-shadow-md: none;
    }


}
