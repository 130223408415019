.record-list {
	grid-column-start: 2;
	grid-column-end: 3;
}

.record-comment {
	background-color: rgba(var(--rgb-medium-gray), 0.2);
	padding: .5em 1em;
	border-radius: 1em;
	margin: 0;
}