.fade-in-up {
    animation: fade-in-up 200ms;
}

@keyframes fade-in-up {
    0% {
        transform: translateY(50%);
        opacity: 0;
    }

    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

.fade-out {
    animation: fade-out 200ms;
    opacity: 0;
}

@keyframes fade-out {
    0% {
        transform: scale(1);
        opacity: 1;
    }

    100%{
        transform: scale(0.8);
        opacity: 0;
    }
}

.kalkyle-child-card:hover {
    @apply h-8 bg-opacity-100;
}


.lift-from-paper {
    @apply transition-all duration-75 ease-in-out;
}

.lift-from-paper:hover {
    transform: scale(1.05, 1.05);
    z-index: 50;
}

.lift-from-paper-sm:hover {
    transform: scale(1.025, 1.025);
    z-index: 50;
}

.flip-x {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
}

.popover-gradient {
    @apply bg-gradient-to-br from-blue-400 via-indigo-400 to-purple-500;
}

.font-gradient {
   /* background: -webkit-linear-gradient(#eee, #333);*/
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.homepage-border {
    @apply bg-opacity-5 dark:bg-opacity-100 rounded-xl border-4 border-opacity-10 dark:border-gray-700 dark:bg-gray-800;
}

.mega {
    background: -webkit-linear-gradient(45deg, #f7ff00, #db36a4);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}