.drawing-button {
	display: grid;
	padding: 1em 0;
	text-align: left;
	border-radius: 1em;
	text-decoration: none;
	cursor: pointer;

	width: 100%;
	box-sizing: border-box;

	grid-column-gap: 0.5rem;
	grid-template-columns: auto 1fr;
	grid-template-areas:
    		"icon  filename"
    		"icon  description";
}

.drawing-button > p {
	padding: 0;
	margin: 0;
}

.drawing-button > .icon {
	grid-area: icon;
	justify-self: center;
	align-self: center;
	padding: 0.5rem;
	font-size: 1.4rem;
}

.drawing-button > .filename {
	grid-area: filename;
	font-weight: bold;
}

.drawing-button > .description {
	grid-area: description;
	color: var(--depth-1);
	font-size: .95em;
	font-weight: normal;
}

@media (min-width: 800px) {
	.drawing-button-hover:hover {
		background-color: rgba(var(--user-color), 0.08);
	}
}

.depth-1 {
	color: var(--depth-1);
}