

.production-line {
	display: grid;
	padding: 1rem;
}

.kanban-line-item > p {
	margin: 0;
	padding: 0;
}

.compact {
	margin: 0;
	padding: 0;
}

.production-line-title {
	font-weight: bold;
}

.item-line {
	width: 2px;
	left: 15px;
	opacity: 0.5;
}

.mega {
	background: -webkit-linear-gradient(45deg, #f7ff00, #db36a4);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

.file-columns {
	grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr));
	grid-gap: 1rem;
}

.hdg--divider {
	@apply relative z-10;
}

.hdg--divider::before {
	content: '';
	height: 1px;
	z-index: -1;
	flex-grow: 1;

	@apply left-0 right-0 top-1/2 absolute;
	@apply bg-gradient-to-r;
	@apply from-pink-400 to-purple-600 dark:from-pink-400 dark:to-indigo-300;
}

.hdg--divider-pink {
	@apply relative z-10;
}

.hdg--divider-pink::before {
	content: '';
	height: 2px;
	z-index: -1;
	flex-grow: 1;

	@apply left-0 right-0 top-1/2 absolute;
	@apply bg-gradient-to-r;
	@apply from-blue-600 to-purple-600 dark:from-pink-400 dark:to-indigo-300;
}

.hdg--divider-button {
	@apply border rounded-full;
	@apply px-4 py-1;
	@apply border-purple-600 dark:border-indigo-300;
	@apply text-purple-600 dark:text-indigo-300;
}
