.operation_icon_base {
	font-size: calc(13px * 2);
	width: calc(16px * 2);
	text-align: center;

	--fa-secondary-opacity: 1;
	--fa-primary-opacity: 0;

	@apply bg-white dark:bg-gray-800;
}

.operation-queued {
	@apply text-gray-300;
}
.operation-in-progress {
	--fa-secondary-color: var(--orange);
}
.operation-finished {
	--fa-secondary-color: var(--green);
}

@media (prefers-color-scheme: dark) {
	.operation-queued {
		--fa-primary-color: #374151;
		color: #6B7280;
	}
}