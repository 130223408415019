@keyframes slide-in-from-left {
	0% {
		opacity: 0%;
	}

	40% {
		opacity: 0%;
		transform: translateX(320px);
	}

	80% {
		opacity: 100%;
		transform: translateX(0px);
	}

}

.slide {
	animation: slide-in-from-left 0.5s both;
}

@keyframes show-gracefully {
	0% {
		opacity: 0;
		transform: translateY(100%);
	}

	40% {
		opacity: 0;
	}

	80% {
		opacity: 1;
		transform: translateY(0);
	}
}

.show-gracefully {
	animation: show-gracefully 0.3s both ease-out;
}

@keyframes show-gracefully-up {
	0% {
		opacity: 0;
		transform: translateY(-100vh);

	}
	40% {
		opacity: 0;
	}

	100% {
		opacity: 1;
		transform: translateY(0px);
	}
}