.content-width {
	display: block;
	margin: auto;
	max-width: var(--content-width);
}

.content-cancel {
	position: absolute;
	top: -11px;
	left: -11px;
	height: 44px;
	width: 44px;

}

@media (max-width: 800px) {
	.content-cancel {
		top: -22px;
		left: 11px;
	}
	.entry-composer-menu {
		padding-top: 1em;
	}
}

.content-cancel:hover {
}


.left-slide {
	animation: left-slie 0.2s both;
}

@keyframes left-slie {
	0% {
		transform: scale(1);
		opacity: 0;
		left: calc(50% - 1.5rem);
	}

	100% {
		transform: scale(0.8);
		opacity: 1;
		left: calc(50% - 3rem);
	}
}



.pop-open {
	animation: pop-open 0.1s both;
}


@keyframes pop-open {
	0% {
		transform: scale(0.3);
		opacity: 0;
	}

	75% {
		transform: scale(1.05);
		opacity: 1;
	}

	100%{
		transform: scale(1);
	}
}

* {
	--color-secondary: rgb(0, 116, 228);
	--color-tertiary: rgb(85, 34, 250);
	--color-group-action: rgba(255, 255, 255, 0.1)
}

.popover {
	display: flex;
	flex-direction: column;

	overflow-y: scroll;
	padding: 1em 1em 1em 1em;

	position: absolute;
	max-height: 400px;
	width: 20rem;

	box-sizing: border-box;

	animation: pop-open 0.1s both;
	z-index: 1000;

	border-radius: 1rem;

	background: linear-gradient(135deg, var(--color-secondary) 0%, var(--color-tertiary) 100%);
}

.top {
	top: 100%;
}

.left {
	left: 1em;
}

@media (max-width: 800px) {
	.popover {
		left: 1em;
		right: 1em;
		width: auto;
	}
}

.popover * {
	color: white;
}

.popover-container {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-gap: .5em;
	margin-top: .5em;
}

.popover-container > * {
	padding: 1em 0;
	border-radius: .5em;
}

.popover > * {
	font-weight: bold;
	padding: 0;
	margin: 0;
}

.popover > .popover-container > * {
	background-color: var(--color-group-action);
	color: white;
}

.entry-square > .quantity {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	background: rgba(var(--user-color), 0.2);
	z-index: 0;
	transition: 0.2s ease-out right;
}

.entry-square > button, p {
	z-index: 10;
}

/* --- */
.entry-square {
	position: relative;
	background-color: rgba(var(--rgb-medium-gray), 0.3);
	border-radius: .5em;
	padding: 0;
	min-width: 6em;
	overflow: hidden;
	align-self: stretch;
}

.entry-square > p {
	max-width: 100%;

}

.entry-square.selected {
	background: rgba(var(--user-color), 0.2);
}

.entry-text-input {
	position: relative;
	background-color: rgba(var(--rgb-medium-gray), 0.3);
	border-radius: .5em;
	font-size: 1em;
	padding: 0.5em;
	border: 2px solid transparent;
	outline: none;
}

.entry-text-input:focus {
	border-color: rgba(var(--user-color), 0.6);
}

/* --- */
.entry-composer-menu {
	position: relative;
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(10em, 1fr));
	grid-gap: 1em;
}

.entry-composer-menu > * {
	min-height: 5em;
}

.center {
	text-align: center;
	justify-content: center;
}

.black {
	color: var(--text-color) !important;
}

/* --- */
.submit-composer-menu {
	display: flex;
	justify-content: space-between;
}

.submit-composer-menu > .submit {
	background-color: var(--green-bg);
	border: 2px solid var(--green);
	color: var(--green);
	padding: .8em 1.6em;
	border-radius: 1em;
}

.submit > i {
	font-size: 1.2em;
}

.submit-composer-menu > .submit:hover {
	background-color: var(--green);
	color: white;
}