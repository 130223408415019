.spinner {
	display: block;
	margin: auto;
	fill: white;
	height: 22px;
	width: 22px;
	animation: spin 2s linear infinite;

}

@keyframes spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}